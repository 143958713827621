<template>
   <div class="service-verify" style="overflow: auto">
       <div class="head" style="width: 1500px; min-height: 170px;background: #FFFFFF; margin: 10px auto;">
           <ul style="display: flex;padding: 0 40px">
               <li v-for="(item,index) in flowList" :key="index" class="info">
                   <!--  圆圈节点  -->
                   <div :class="{active: index  < serviceInfo.status}"  class="primary"><i :class="item.icon"></i></div>
                   <!--  线   -->
                   <div :class="{active: index  < serviceInfo.status - 1}" class="error" v-if="index <= 3"></div>
                   <!--  标注    -->
                   <div class="dark">{{ item.title }}</div>
               </li>
           </ul>
       </div>
       <div style="width: 1500px; min-height: 600px;background: #FFFFFF; margin: 0 auto">
           <ServiceTable ref="serviceTable" :serviceInfo="serviceInfo"></ServiceTable>
           <div style="margin: 30px 0; padding-bottom: 40px; display: flex; justify-content: end">
               <div class="foot-btn hand op" style="color: #2970FF; border: 1px #2970FF solid" @click="submitOrderInfo">提交订单信息</div>
           </div>
       </div>
   </div>
</template>

<script>
import ServiceTable from "./components/ServiceTable";
export default {
    name: "serviceVerify",
    components: {ServiceTable},
    data() {
        return {
            demandInfo: {},
            show: 0,
            flowList: [
                {
                    status: 1,
                    title: '填写服务需求',
                    icon: 'el-icon-position'
                },
                {
                    status: 2,
                    title: '查看处理需求',
                    icon: 'el-icon-check'
                },
                {
                    status: 3,
                    title: '填写付款方式',
                    icon: 'el-icon-check'
                },
                {
                    status: 4,
                    title: '确认付款方式',
                    icon: 'el-icon-check'
                },
                {
                    status: 5,
                    title: '服务订单生效',
                    icon: 'el-icon-s-check'
                }
            ],

            serviceInfo: {},
            checked: false,
            input: '',
            roomId:0,
            sequenceId:0
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        }
    },
    created() {

    },
    mounted() {
        var roomId = this.$route.query.roomId;
        if (roomId){
            this.roomId = roomId;
        }
        var sequenceId = this.$route.query.sequenceId;
        if (sequenceId){
            this.sequenceId = sequenceId;
        }
        this.getServiceDetail();
    },
    methods: {
        // 提交订单信息
        submitOrderInfo() {
            let serviceInfo = this.$refs.serviceTable.submitInfo()
	        if (serviceInfo.name==""){
		        this.utils.err("需求名称不可为空");
                return;
	        }
	        if (serviceInfo.demand==""){
		        this.utils.err("需求内容不可为空");
                return;
	        }
	        if (serviceInfo.input[serviceInfo.input.length - 1].content=="" && serviceInfo.input[serviceInfo.input.length - 1].status==0){
		        this.utils.err("服务输入条件备注不可为空");
                return;
	        }
	        if (serviceInfo.output[serviceInfo.output.length - 1].content=="" && serviceInfo.output[serviceInfo.output.length - 1].status==0){
		        this.utils.err("服务输出条件备注不可为空");
                return;
	        }
	        if (serviceInfo.standard[serviceInfo.standard.length - 1].content=="" && serviceInfo.standard[serviceInfo.standard.length - 1].status==0){
		        this.utils.err("服务达到的标准备注不可为空");
                return;
	        }

            this.newApi.procurementServices({demandId: 0, serviceId: this.$route.params.id, demandInfo: JSON.stringify(serviceInfo), roomId: this.roomId, questId: this.sequenceId, shopId: this.serviceInfo.shopId}).then(res =>{
                if (res.isSuccess === 1) {
                    this.utils.sus(res.data);
                    this.utils.a('/shop/order/')
                }
            })
        },

        // 获取服务详情
        getServiceDetail() {
            this.newApi.getShopServiceInfo({serviceId: this.$route.params.id}).then(res => {
                if (res.isSuccess === 1) {
                    for (const item of res.data.otherSpecifications) {
                        item.status = 1;
                        item.content = '';
                        if (item.type === 4) {
                            item.data = []
                        }
                        if (item.type === 6) {
                            item.data = []
                            for (const i of item.value) {
                                i.status = 1;
                                i.content = '';
                            }
                        }
                    }
                    res.data.input.push({status: 1, content: ''});
                    res.data.output.push({status: 1, content: ''});
                    res.data.standard.push({status: 1, content: ''});
                    res.data.name = '';
                    res.data.price = '';
                    this.serviceInfo = res.data;
                    this.show = 2;
                }
            })
        },
    }
}
</script>

<style scoped>
    .service-verify {
        height: 100vh;
        overflow: hidden;
        background:#f0f0f0;
    }

    .dark {
        margin-top: 10px;
        color: #444444;
        font-size: 18px;
    }

    .info {
        display: inline-block;
        width: 320px;
        padding-top: 40px;
    }

    .primary {
        box-sizing: border-box;
        border-radius: 50%;
        cursor: pointer;
        color: #031F88;
        background: #E8F2FB;
        width: 40px;
        height: 40px;
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        margin: auto 30px;
        line-height: 38px;
    }
    .primary.active {
        background-color: #FFFFFF !important;
        border: 3px #031F88 solid;
        border-radius: 50%;
    }

    .error.active{
        border-top: 10px solid #031F88;
    }

    .error {
        width: 300px;
        margin-left: 70px;
        margin-top: -22px;
        height: 10px;
        border-top: 10px solid #E8F2FB;
    }

    .service-verify .foot-btn {
        padding: 10px 36px;
        font-size: 16px;
        margin: 0 20px;
        font-weight: 600;
        border-radius: 5px;
    }
</style>
